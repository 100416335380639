<template>
  <div id="app">
    <Header></Header>
    <div class='main-router'>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import EventBus from '@/event-bus';

export default {
  name: 'App',
  components: {
    Header
  },
  created () {
    EventBus.$on( 'jj-toast', this.$jjToast );
  },
  beforeDestroy () {
    EventBus.$off( 'jj-toast', this.$jjToast );
  }
}
</script>

<style lang='scss'>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.main-router {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  > :first-child {
    flex: 1 1 0;
  }
}
</style>
