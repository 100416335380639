<template>
  <div class="loading" :class='{ centered, opaque }'>
    <b-spinner class="align-middle"></b-spinner>
    <strong class="ml-2">Loading...</strong>
  </div>
</template>


<script>
export default {
  name: 'jjLoading',
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    opaque: {
      type: Boolean,
      default: false,
    },
  }
}
</script>


<style lang='scss' scoped>
.loading {
  text-align: center;
  color: var( --danger );
  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
  }
  &:not(.opaque) {
    opacity: 0.6;
  }
}
</style>