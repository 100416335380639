import axios from '@/axios';
import _ from 'lodash';

export default {

  namespaced: true,


  state: {
    loading: false,
    order: null,
    item_changes_pending: false
  },


  mutations: {
    loading ( state, payload ) {
      state.loading = Boolean( payload );
    },
    order ( state, payload ) {
      if ( Array.isArray( payload.line_items ) ) {
        payload.line_items = {};
      }
      state.order = payload;
      state.item_changes_pending = false;
    },
    set_order_prop ( state, payload ) {
      let order = _.cloneDeep( state.order );
      _.set( order, payload.prop, payload.value );
      state.order = order;
      if ( payload.prop.split( '.' ).includes( 'line_items' ) ) {
        state.item_changes_pending = true;
      }
    },
    unset_order_prop ( state, path ) {
      const order = { ...state.order };
      _.unset( order, path );
      state.order = order;
      if ( path.split( '.' ).includes( 'line_items' ) ) {
        state.item_changes_pending = true;
      }
    },
    set_item_changes_pending ( state, payload ) {
      state.item_changes_pending = Boolean( payload );
    },
    
  },


  actions: {

    async load_order ( { commit }, id ) {
      commit( 'loading', true );
      let response = await axios.get( `orders/${ id }` );
      if ( response.status == 200 ) {
        commit( 'order', response.data );
      } else {
        console.error( 'Error loading order' );
      }
      commit( 'loading', false );
    },

  },

}