import Toast from './toast';
import _ from 'lodash';

export default {
  install( Vue, options ) {

    Vue.prototype.$jjToast = function ( props = {}, options = {} ) {
      const id = _.uniqueId( 'jj-toast' );

      this.$bvToast.toast( [ this.$createElement( Toast, { props }) ], Object.assign({
        toaster: 'b-toaster-bottom-right',
        noAutoHide: ( _.get( props, 'type', '' ) == 'busy' ),
        id,
        title: _.get( props, 'title', '' ),
      }, options ));

      return id;
    }

  },
};