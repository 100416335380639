import Vue from 'vue';

import App from './App';
import router from './routes/router.js';

import store from './store/index.js';
store.dispatch( 'auth/init_token_from_cookie', null, { root: true } );

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';


import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// import scss
// gets spliced out into own file by webpack
import '@/scss/main.scss';


// reusable component for icon btn
import EditIconBtn from "@/components/edit-icon-btn";
Vue.component( "edit-icon-btn", EditIconBtn );


// define custom number formatting filters
import { money_format, separate_thousands } from '@/functions/formatting';
Vue.filter( 'money_format', money_format );
Vue.filter( 'separate_thousands', separate_thousands );


import vSelect from "vue-select";
Vue.component( "v-select", vSelect );


// click-outside directive
import VueClickOutsideElement from 'vue-click-outside-element';
Vue.use( VueClickOutsideElement );


// 3rd-party component for nice select boxes with multiselect capability
import VueMultiselect from 'vue-multiselect';
Vue.component( 'vue-multiselect', VueMultiselect );
import 'vue-multiselect/dist/vue-multiselect.min.css';


import jjToasts from '@/plugins/jjToasts/plugin';
Vue.use( jjToasts );


// custom loading component
import jjLoading from '@/components/loading';
Vue.component( 'jj-loading', jjLoading );


// FA
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faMinus, faCheck, faSpinner, faCaretDown, faFloppyDisk, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faTriangleExclamation, faUpload, faDownload, faArrowsRotate, faCog, faClipboardList, faTruck, faFileChartColumn, faClone as faCloneR, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faBoxOpenFull, faReceipt, faRightFromBracket, faClone as faCloneL } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
library.add(
  faPlus,
  faMinus,
  faCheck,
  faSpinner,
  faCaretDown,
  faFloppyDisk,
  faTriangleExclamation,
  faUpload,
  faDownload,
  faArrowsRotate,
  faCog,
  faClipboardList,
  faTruck,
  faChevronLeft,
  faChevronRight,
  faBoxOpenFull,
  faReceipt,
  faRightFromBracket,
  faXmark,
  faCircleCheck,
  faCloneL,
  faCloneR,
  faFileChartColumn,
);
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component( 'font-awesome-icon', FontAwesomeIcon );





Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
