import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

// modules
import auth from './modules/auth';
import orders from './modules/orders';
import order_details from './modules/order-details';
import inventory_details from './modules/inventory-details';

export default new Vuex.Store({
  modules: {
    auth,
    orders,
    order_details,
    inventory_details
  }
})