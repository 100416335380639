<template>
  <div :class='`jj-toast type--${ type }`'>
    <font-awesome-icon :icon='icon' class='icon' :class='{ "fa-spin": ( type == `busy` ) }' />
    <span>{{content}}</span>
  </div>
</template>


<script>
export default {
  name: 'jjToast',
  props: {
    type: {
      type: String,
      required: true,
      default: 'success',
    },
    content: {
      type: String,
      required: true,
      default: '',
    }
  },
  computed: {
    icon () {
      switch ( this.type ) {
        case 'error':
          return 'fa-duotone fa-circle-xmark';
        case 'busy':
          return 'fa-solid fa-spinner';
      }
      return 'fa-duotone fa-circle-check';
    }
  },
}
</script>


<style lang="scss" scoped>
.jj-toast {
  padding-left: 1.75em;
  position: relative;
  .icon {
    position: absolute;
    top: .15em;
    left: 0;
    font-size: 1.2em;
    --fa-primary-opacity: 1;
    --fa-secondary-opacity: 1;
    --fa-primary-color: var( --white );
  }  
  &.type--error .icon {
    --fa-secondary-color: var( --danger );
  }
  &.type--success .icon {
    --fa-secondary-color: var( --success );
  }
  &.type--busy .icon {
    color: var( --blue );
  }
}
</style>