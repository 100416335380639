import Big from 'big.js';

export function separate_thousands ( value ) {
  var arr = value.toString().split('.');
  arr[0] = arr[0].replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
  return arr.join( '.' );
}

export function money_format ( value ) {
  return `$${ separate_thousands( Big( value ).toFixed( 2 ) ) }`;
}