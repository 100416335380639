import axios from '@/axios';
import _ from 'lodash';

export default {

  namespaced: true,


  state: {
    loading: false,
    record: null,
    item_changes_pending: false
  },


  mutations: {
    loading ( state, payload ) {
      state.loading = Boolean( payload );
    },
    record ( state, payload ) {
      if ( Array.isArray( payload.line_items ) ) {
        payload.line_items = {};
      }
      state.record = payload;
      state.item_changes_pending = false;
    },
    set_record_prop ( state, payload ) {
      let record = _.cloneDeep( state.record );
      _.set( record, payload.prop, payload.value );
      state.record = record;
      if ( payload.prop.split( '.' ).includes( 'line_items' ) ) {
        state.item_changes_pending = true;
      }
    },
    unset_record_prop ( state, path ) {
      const record = { ...state.record };
      _.unset( record, path );
      state.record = record;
      if ( path.split( '.' ).includes( 'line_items' ) ) {
        state.item_changes_pending = true;
      }
    },
    set_item_changes_pending ( state, payload ) {
      state.item_changes_pending = Boolean( payload );
    },
    
  },


  actions: {

    async load_record ( { commit }, id ) {
      commit( 'loading', true );
      let response = await axios.get( `inventory/record/${ id }` );
      if ( response.status == 200 ) {
        commit( 'record', response.data );
      } else {
        console.error( 'Error loading record' );
      }
      commit( 'loading', false );
    },

  },

}