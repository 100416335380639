import axios from '@/axios'

export default {

  namespaced: true,

  state: {
    token: null,
  },

  getters: {
    token ( state ) {
      return state.token;
    },
  },

  mutations: {
    token ( state, token ) {
      if ( token ) {
        const d = new Date();
        d.setTime( d.getTime() + ( 60 * 60 * 1000 ) ); // now + 1hr
        document.cookie = `jj-auth=${ token }; expires=${ d.toUTCString() }; path=/`;
      } else {
        document.cookie = "jj-auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
      state.token = token;
    }
  },

  actions: {
    
    async login ({ commit } , payload ) {
      try {
        const response = await axios({
          url: `${ process.env.VUE_APP_BASE_SITE_URL }/wp-json/jwt-auth/v1/token`,
          method: 'post',
          data: payload,
          noAuth: true,
        });
        if ( response.data ) {
          commit( 'token', response.data.token );
          return { success: true };
        } else {
          this.dispatch( 'clear_token' );
          return { success: false };
        }
      } catch ( e ) {
        return { success: false };
      }
    },

    clear_token ({ commit }) {
      commit( 'token', null );
    },

    init_token_from_cookie ({ commit }) {
      let token = null;
      document.cookie.split( ';' ).forEach( function( el ) {
        let [key,value] = el.split('=');
        if ( key.trim() == 'jj-auth' ) {
          token = value.trim();
        }
      });
      commit( 'token', token );
    },

  },

}