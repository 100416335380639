<template>
  <div class="header">
    <b-navbar>

      <b-navbar-brand>
        <img alt="J&J Crafts" src="../assets/logo.png" width="175" height="48">
      </b-navbar-brand>

      <b-navbar-nav v-if='token'>
        <b-nav-item :to="{ name: 'orders' }">
          <font-awesome-icon icon="fa-light fa-receipt" />
          <span>Orders</span>
        </b-nav-item>
        <b-nav-item :to="{ name: 'inventory' }">
          <font-awesome-icon icon="fa-light fa-box-open-full" />
          <span>Inventory</span>
        </b-nav-item>
        <b-nav-item :to="{ name: 'logout' }">
          <font-awesome-icon icon="fa-light fa-right-from-bracket" flip='horizontal' />
          <span>Logout</span>
        </b-nav-item>
      </b-navbar-nav>

    </b-navbar>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  computed: {
    ...mapGetters( 'auth', [
      'token',
    ]),
  },
}
</script>


<style scoped lang='scss'>
  nav {

    border-bottom: 1px solid var( --bs-border-color );
    padding: 0;
    align-items: stretch;

    .navbar-brand {
      padding: 22px 19px;
    }

    .nav-item {
      
    }

    .nav-link {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      padding: 22px !important;
      font-size: 14px;
      color: var( --dark ) !important;
      transition: var( --transition-timing );
      &:hover,
      &:focus,
      &.router-link-active {
        color: white !important;
        background-color: var( --primary );
      }
      .svg-inline--fa {
        font-size: 1.25em;
        margin-right: 0.5em;
      }
    }

  }
</style>