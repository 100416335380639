import axios from 'axios';
import router from '@/routes/router.js';
import _ from 'lodash';
import store from '@/store';
import EventBus from '@/event-bus';

// set base to custom wp api namespace
axios.defaults.baseURL = process.env.VUE_APP_JJ_REST_API;


// automatically append nonce cookie as header
axios.interceptors.request.use( function ( config ) {
  const token = store.getters['auth/token'];
  if ( token && !( config.noAuth ?? false ) ) {
    config.headers.Authorization = `Bearer ${ token }`;
  }
  return config;
}, null, { synchronous: true });


// automatically redirect to login on 401/403 response
// first param is success callback (2xx) status
// second is error callback (!2xx)
axios.interceptors.response.use(
  response => response,
  error => {
    if ( [ 401, 403 ].includes( _.get( error, 'response.status' ) ) ) {
      if ( router.currentRoute.name != 'login' ) {
        EventBus.$emit( 'jj-toast', {
          type: 'error',
          title: `Session Expired`,
          content: `Your session has expired. You have been automatically logged out.`,
        });
        store.dispatch( 'auth/clear_token', null, { root: true });
        router.push({ name: 'login' });
      }
    }
    return Promise.reject(error);
  }
);


export default axios