import axios from '@/axios';
import router from '@/routes/router';

export default {

  namespaced: true,


  state: {
    statuses: [],
    status_filter: [],
    shipping_methods: [],
    guest_customer: null,
    countries: []
  },


  mutations: {
    statuses ( state, payload ) {
      state.statuses = Object.entries( payload ).map( entry => { return { key: entry[0], label: entry[1] } } );
    },
    status_filter ( state, payload ) {
      state.status_filter = payload;
    },
    shipping_methods ( state, payload ) {
      state.shipping_methods = Object.entries( payload ).map( entry => { return { key: entry[0], label: entry[1] } } );
    },
    guest_customer ( state, payload ) {
      state.guest_customer = payload;
    },
    set_countries ( state, payload ) {
      state.countries = payload;
    }

    
  },


  actions: {
    
    load_statuses ({ state, commit }) {
      if ( state.statuses.length ) { return; }
      axios.get( 'orders/statuses' )
        .then( response => {
          commit( 'status_filter', Object.entries( response.data ).filter( ( [key] ) => ![ 'wc-completed', 'wc-on-hold', 'wc-cancelled', 'wc-refunded' ].includes( key ) ).map( entry => { return { key: entry[0], label: entry[1] } } ) );
          commit( 'statuses', response.data );
        })
    },


    load_shipping_methods ({ state, commit }) {
      if ( state.statuses.length ) { return; }
      axios.get( 'orders/shipping_methods' )
        .then( response => {
          commit( 'shipping_methods', response.data );
        })
    },


    load_guest_customer ({ state, commit }) {
      if ( state.guest_customer ) { return; }
      axios.get( 'customers/guest' )
        .then( response => {
          commit( 'guest_customer', response.data );
        })
    },

    load_countries( { commit, state } ) {
      if ( state.countries.length ) { return; }
      axios.get( `${ process.env.VUE_APP_BASE_SITE_URL }/wp-json/wc/v3/data/countries` )
        .then( response => {
          commit( 'set_countries', response.data );
        })
    },

    create_new () {
      return new Promise( ( resolve, reject ) => {
        axios.post( '/orders/create' )
          .then( response => {
            router.push({
              name: 'order-details',
              params: {
                id: response.data.id,
              },
            });
            resolve();
          })
          .catch( error => {
            reject( error );
          })
      });
    },
    
    clone_order ( obj, order_id ) {
      return new Promise( ( resolve, reject ) => {
        axios.post( `/orders/clone/${ order_id }` )
          .then( response => {
            router.push({
              name: 'order-details',
              params: {
                id: response.data.id,
              },
            });
            resolve();
          })
          .catch( error => {
            reject( error );
          })
      });
    }

  },

}