import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes.js';
import store from '@/store';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  if ( to.name != 'login' && !store.getters['auth/token'] ) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
})

export default router;