import store from '@/store';

export default [
  {
    name: 'login',
    path: '/login',
    component: () => import( /* webpackChunkName: "login" */ '@/components/Login'),
  },
  {
    name: 'logout',
    path: '/logout',
    beforeEnter: ( to, from, next ) => {
      store.dispatch( 'auth/clear_token', null, { root: true } );
      next({ path: 'login' });
    }
  },
  {
    path: '/inventory',
    component: () => import( /* webpackChunkName: "inventory-archive" */ '@/components/inventory/inventory'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'inventory',
        component: () => import( /* webpackChunkName: "inventory-list" */ '@/components/inventory/list/list'),
      },
      {
        path: 'records',
        component: () => import( /* webpackChunkName: "inventory-records" */ '@/components/inventory/records/records'),
        children: [
          {
            path: '',
            name: 'inventory/records',
            component: () => import( /* webpackChunkName: "inventory-record" */ '@/components/inventory/records/not-selected'),
          },
          {
            path: ':id',
            name: 'inventory/record-details',
            component: () => import( /* webpackChunkName: "inventory-record" */ '@/components/inventory/records/details/index'),
          },
        ]
      },
    ],
  },
  {
    path: '/orders',
    component: () => import( /* webpackChunkName: "orders-archive" */ '@/components/orders/archive'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'orders',
        component: () => import( /* webpackChunkName: "order-details" */ '@/components/orders/not-selected'),
      },
      {
        path: ':id',
        name: 'order-details',
        component: () => import( /* webpackChunkName: "order-details" */ '@/components/orders/details'),
      },
    ],
  },
  {
    path: '*',
    redirect: { name: 'orders' }
  },
];